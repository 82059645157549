<template>
	<div class="animated fadeIn">
		<b-card>
			<vue-loading :active.sync="clientsTableProps.isLoading" :is-full-page="false"/>
			<vue-loading :active.sync="isLoadingDeleting" :is-full-page="false"/>
			<div slot="header">
				<b>Seznam kupcev</b>
				<div class="card-header-actions">
					<CLink title="Osveži tabelo" href="#" class="card-header-action btn-setting" @click.stop="click_clientsTableRefresh">
						<CIcon name="cil-loop-circular"/>
					</CLink>
					<CLink title="Ponastavi filtre" href="#" class="card-header-action btn-setting" @click.stop="click_clientsTableResetFilters">
						<CIcon name="cil-x"/>
					</CLink>
					<CLink title="Dodaj stranko" href="#" class="card-header-action btn-setting" @click.stop="click_clientCreate">
						<CIcon name="cil-plus"/>
					</CLink>
				</div>
			</div>
				<b-table
					ref="clientsTable"
					responsive="sm"
					striped
					show-empty
					hover
					:empty-text="clientsTableProps.empty_text"
					:empty-filtered-text="clientsTableProps.empty_filtered_text"
					:fields="clientsTableProps.fields"
					:current-page="clientsTableProps.currentPage"
					:per-page="clientsTableProps.itemsPerPage"
					:items="clientsTable_items"
					@row-clicked="rowClicked">
					<template slot="top-row">
							<td></td>
							<td><b-form-input v-model="clientsTableProps.inputFilter.title" type="text" @input="clientsTableTextFilter_change"/></td>
							<td><b-form-input v-model="clientsTableProps.inputFilter.email" type="text" @input="clientsTableTextFilter_change"/></td>
							<td></td>
							<td></td>
							<td></td>
					</template>
					<template v-slot:cell(title)="data">
						<div v-if="data.item.is_company"><CIcon name="cil-bank" class="mr-2" title="Podjetje"/>{{ data.item.title }}</div>
						<div v-else  title="Fizična oseba"><CIcon name="cil-user" class="mr-2"/>{{ data.item.title }}</div>
					</template>
					<template v-slot:cell(created_at)="data">
						{{ data.item.created_at | viewDate }}
					</template>
					<template v-slot:cell(actions)="data">
						<div>
							<b-button title="Uredi stranko" variant="primary" @click.stop="click_clientEdit(data.item)" size="sm" class="mr-2">
								<CIcon name="cil-pencil"/>
							</b-button>
							<b-button title="Izbriši stranko" variant="danger" @click.stop="click_clientDelete(data.item)" size="sm" class="mr-2">
								<CIcon name="cil-trash"/>
							</b-button>
						</div>
					</template>
				</b-table>
				<nav>
					<b-row>
						<b-col>
							<b-pagination :total-rows="clientsTableProps.totalItems" :per-page="clientsTableProps.itemsPerPage" v-model="clientsTableProps.currentPage" prev-text="Prejšnja" next-text="Naslednja" hide-goto-end-buttons />
						</b-col>
						
						<b-col cols=2>
							<a class="page-link rows_total float-right">Št. vrstic: {{ clientsTableProps.totalItems }}</a>
						</b-col>
						<b-col cols=1>
							<b-form-select v-model="clientsTableProps.itemsPerPage" :options="clientsTableProps.options_numRowsOnPage"></b-form-select>
						</b-col>
					</b-row>
				</nav>
			</b-card>
		<client-create-edit-modal ref="clientCreateEditModal" @emit_clientCreatedOrEdited="emit_clientCreatedOrEdited"/>
		<vue-toastr ref="toastr"></vue-toastr>
	</div>
</template>

<script>
import settings from '@/settings.js';
import { CLIENTS_LIST_FILTERED, CLIENT_DELETE } from "@/store/actions/clients";

export default {
	name: 'Clients',
	components: {
	},
	data () {
		return {
			clientsTableProps: {
				fields: [
					{ key: 'id', label: 'Id', sortable: true, class: 'tdClientsId' },
					{ key: 'title', label: 'Naziv', sortable: true, class: '' },
					{ key: 'email', label: 'Email', sortable: true, class: 'tdClientsEmail' },
					{ key: 'phone_number', label: 'Telefonska številka', sortable: true, class: 'tdClientsPhoneNumber' },
					{ key: 'created_at', label: 'Ustvarjen dne', sortable: true, class: 'tdClientsCreatedAt' },
					{ key: 'actions', label: '#', sortable: false, class: 'tdClientsActions' },
				],
				currentPage: 1,
				itemsPerPage: 50,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 50, text: '50' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' },
					{ value: 1000, text: '1000' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {
					title: null,
					email: null
				},
				textFilterTimeout: null,
				errorText: ''
			},
			isLoadingDeleting: false
		}
	},
	watch: {
	},
	computed: {
	},
	methods: {
		click_clientCreate: function () {
			let modalInput = {
				mode: 1, // create
				client: null
			}
			this.$refs.clientCreateEditModal.showModal(modalInput);
		},
		click_clientEdit: function (data) {
			let modalInput = {
				mode: 2, // edit
				client: data
			}
			this.$refs.clientCreateEditModal.showModal(modalInput);
		},
		click_clientDelete: function (data) {
			this.$bvModal.msgBoxConfirm('Želite izbrisati stranko ' + this.mixinGetClientFullName(data) + ' (#' + data.id + ')?', {
				title: 'Potrdite brisanje stranke',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingDeleting = true;
					this.$store.dispatch(CLIENT_DELETE, { id: data.id })
						.then((responseData) => {
							this.isLoadingDeleting = false;
							this.$refs.toastr.s('Stranka ' + this.mixinGetClientFullName(data) + ' uspešno izbrisana.');
							this.$refs.clientsTable.refresh();
						})
						.catch(error => {
							this.isLoadingDeleting = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri brisanju! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri brisanju! Prosimo poizkusite kasneje.');
							}
						});
				}
			}).catch(err => {
				console.error(err);
			})
		},
		click_clientsTableRefresh: function () {
			this.$refs.clientsTable.refresh();
		},
		click_clientsTableResetFilters: function () {
			this.clientsTableProps.inputFilter.title = null;
			this.clientsTableProps.inputFilter.email = null;
			this.$refs.clientsTable.refresh();
		},
		emit_clientCreatedOrEdited: function (data) {
			if (data.message === 'created') {
				this.$refs.toastr.s('Stranka ' + this.mixinGetClientFullName(data.client) + ' uspešno dodana.');
				this.$refs.clientsTable.refresh();
			} else if (data.message === 'edited') {
				this.$refs.toastr.s('Stranka ' + this.mixinGetClientFullName(data.client) + ' uspešno urejena.');
				this.$refs.clientsTable.refresh();
			} else {
				this.$refs.toastr.e('Neznan odziv strežnika.');
				this.$refs.clientsTable.refresh();
			}
		},
		clientsTable_items: function (ctx) {
			this.clientsTableProps.isLoading = true;
			return this.$store.dispatch(CLIENTS_LIST_FILTERED, { ctx: ctx, tableProps: this.clientsTableProps })
				.then((responseData) => {
					this.clientsTableProps.isLoading = false;
					this.clientsTableProps.items = responseData.clientsPagination.data;
					this.clientsTableProps.totalItems = responseData.clientsPagination.total;
					this.clientsTableProps.empty_text = 'Ni vnosov';
					return responseData.clientsPagination.data;
				})
				.catch(error => {
					this.clientsTableProps.isLoading = false;
					this.clientsTableProps.items = [];
					this.clientsTableProps.totalItems = 0;
					this.clientsTableProps.empty_text = 'Napaka pri pridobivanju strank';
					console.log(error);
					return [];
				});
		},
		clientsTableTextFilter_change: function (value) {
			if (this.clientsTableProps.textFilterTimeout !== null) {
				clearTimeout(this.clientsTableProps.textFilterTimeout);
			}
			this.clientsTableProps.textFilterTimeout = setTimeout(() => {
				this.$refs.clientsTable.refresh();
			}, settings.filterTimeoutLength);
		},
		rowClicked (row) {
			let modalInput = {
				mode: 2, // edit
				client: row
			}
			this.$refs.clientCreateEditModal.showModal(modalInput);
		},
	},
	created: function () {
	}
}
</script>
<style scoped>
.table-responsive-sm {
	margin-bottom: 0;
}
.pagination {
	margin-left: 1rem;
	margin-right: 1rem;
}
.page-link.rows_total {
	text-align: center;
	border-radius: 5px;
	width: 120px;
}
</style>

<style>
.tdClientsId {
	width: 120px;
}
.tdClientsActions {
	width: 120px;
}
.tdClientsEmail {
	width: 220px;
}
.tdClientsPhoneNumber {
	width: 220px;
}
.tdClientsCreatedAt {
	width: 220px;
}
</style>